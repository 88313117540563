import React from "react"
import styled from "styled-components"

import DeviceMockupSection from "../animations/DeviceMockupSection"
import TextSection from "./TextSection"

const PluginSection = () => {
  return (
    <Wrapper>
      <Wave />
      <Container>
        <DeviceMockupSection />
        <TextSection
          title="Place your screen with Angle plugin"
          description="We've created a free Plugin that allows you to place your screen into perspective
            mockups. Download 50 free mockups and try the plugin."
          forPlugin={true}
        />
      </Container>
    </Wrapper>
  )
}

export default PluginSection

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 200px;
  height: 1200px;

  .text-wrapper {
    h3,
    p {
      color: #ffffff;
    }
  }

  @media (max-width: 1050px) {
    .text-wrapper {
      h3,
      p {
        color: #000000;
      }
    }

    @media (prefers-color-scheme: dark) {
      .text-wrapper {
        h3,
        p {
          color: #ffffff;
        }
      }
    }
  }
`

const Container = styled.div`
  position: relative;
  display: grid;
  max-width: 1234px;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: 587px 372px;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
    gap: 100px;
    justify-content: center;
    justify-items: center;
  }
`

const Wave = styled.div`
  position: absolute;
  width: 100%;
  height: 1510px;
  top: -300px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/images/backgrounds/plugin-wave.svg");
`
