import React from "react"
import styled, { keyframes } from "styled-components"
import { graphql, navigate } from "gatsby"

import Layout from "../components/layout/Layout"
import { MetadataReadonly } from "../types/contentfulTypes"
import TextSection from "../components/sections/TextSection"
import DownloadSection from "../components/sections/DownloadSection"
import PluginSection from "../components/sections/PluginSection"

interface Props {
  data: {
    contentfulMetadata: MetadataReadonly
  }
}

const Downloads = (props: Props) => {
  const { data } = props

  return (
    <Layout {...data}>
      <Wrapper>
        <Background />
        <Container>
          <div />
          <TextSection
            title="One place for all your Mockups"
            description="Our mockups work with your favorite design tools: Sketch, Figma and XD. Find all the most up-to-date mockup files and plugins here."
            buttonTitle="View Full Library"
            buttonIcon="/images/icons/device-pink.svg"
            onClick={() => navigate("/mockups")}
          />
        </Container>
        <DownloadSection />
        <PluginContainer>
          <PluginSection />
        </PluginContainer>
      </Wrapper>
    </Layout>
  )
}

export default Downloads

export const query = graphql`
  query DownloadsQuery {
    contentfulMetadata(slug: { eq: "downloads" }) {
      ...MetadataFragment
    }
  }
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
 100% {
    opacity: 1;
  }
`

const Wrapper = styled.div`
  position: relative;
  padding: 200px 0px;
  overflow: hidden;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  max-width: 1234px;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: 720px) {
    grid-template-columns: 1fr;
    gap: 500px;
    align-items: center;
  }

  .text-wrapper {
    margin: 0 auto;
  }
`

const Background = styled.div`
  position: absolute;
  width: 1985px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;
  top: 120px;
  left: 0;
  height: 1487px;
  background-image: url("/images/illustrations/downloads.png");
  z-index: -1;
  opacity: 0;
  animation: ${fadeIn} 1s 0.5s linear forwards;

  @media (max-width: 1500px) {
    left: -10px;
  }

  @media (max-width: 1200px) {
    left: -20%;
  }

  @media (max-width: 1050px) {
    left: -30%;
  }

  @media (max-width: 950px) {
    left: -40%;
  }

  @media (max-width: 850px) {
    left: -50%;
  }

  @media (max-width: 770px) {
    left: -60%;
  }

  @media (max-width: 500px) {
    left: -100%;
  }

  @media (max-width: 400px) {
    left: -120%;
  }
`

const PluginContainer = styled.div`
  height: 1400px;
  padding-top: 200px;

  @media (max-width: 600px) {
    height: 1200px;
  }

  @media (max-width: 400px) {
    height: 1000px;
  }
`
