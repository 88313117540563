import React from "react"
import styled from "styled-components"
import useWindowSize from "../../utils/hooks/useWindowSize"

import DownloadCard from "../cards/DownloadCard"

const DownloadSection = () => {
  const { width } = useWindowSize()

  const ResponsiveCards = () => {
    if (width >= 1340) {
      return (
        <Container>
          <DownloadCard type="sketch" />
          <Separator />
          <DownloadCard type="figma" />
          <Separator />
          <DownloadCard type="xd" />
          <Separator />
          <DownloadCard type="wallpaper" />
        </Container>
      )
    }
    if (width < 1340 && width >= 690) {
      return (
        <>
          <Container>
            <DownloadCard type="sketch" />
            <Separator />
            <DownloadCard type="figma" />
          </Container>
          <Container>
            <DownloadCard type="xd" />
            <Separator />
            <DownloadCard type="wallpaper" />
          </Container>
        </>
      )
    }
    if (width < 690) {
      return (
        <>
          <Container>
            <DownloadCard type="sketch" />
          </Container>
          <Container>
            <DownloadCard type="figma" />
          </Container>
          <Container>
            <DownloadCard type="xd" />
          </Container>
          <Container>
            <DownloadCard type="wallpaper" />
          </Container>
        </>
      )
    }
  }

  return <Wrapper>{ResponsiveCards()}</Wrapper>
}

export default DownloadSection

const Wrapper = styled.div`
  position: relative;
  display: grid;
  gap: 50px;
  padding: 360px 20px 0;
  z-index: 2;
`

const Container = styled.div`
  display: flex;
  max-width: 1300px;
  width: 100%;
  height: 454px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 30px 30px rgba(69, 42, 124, 0.15);
  backdrop-filter: blur(60px);
  border-radius: 30px;

  @media (max-width: 1339px) {
    max-width: 650px;
  }

  @media (max-width: 689px) {
    max-width: 325px;
  }
`

const Separator = styled.div`
  width: 1px;
  height: auto;
  background: rgba(0, 0, 0, 0.1);
`
