import React from "react"
import styled from "styled-components"

import useDownload from "../../utils/hooks/useDownload"
import { DownloadType } from "../../types/downloadTypes"
import { downloadData } from "../../data/DownloadData"

import PurchaseButton from "../buttons/PurchaseButton"
import * as TextStyles from "../styles/TextStyles"

interface Props {
  type: string
}

const DownloadCard = (props: Props) => {
  const { type } = props

  const data = downloadData.filter(data => data.type === type)[0]
  const { logo, title, description, library: downloadType } = data

  const { download, buttonTitle } = useDownload(downloadType as DownloadType)

  return (
    <Wrapper>
      <Background className="background" />
      <Container>
        <LogoContainer className="logo">
          <Logo src={logo} alt="logo" />
        </LogoContainer>
        <Title className="title">{title}</Title>
        <Description className="description">{description}</Description>
        <PurchaseButton
          title={buttonTitle}
          icon="/images/icons/downloads-pink.svg"
          onClick={download}
        />
      </Container>
    </Wrapper>
  )
}

export default DownloadCard

const Wrapper = styled.div`
  position: relative;
  max-width: 325px;
  width: 100%;
  height: 100%;
  padding: 30px;

  &,
  * {
    transition: 2s cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition: 2s cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: 2s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: 2s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .purchase {
    display: none;
    opacity: 0;
    visibility: hidden;
    width: 100%;

    p {
      font-size: 18px;
    }
  }

  :hover {
    .logo {
      background: linear-gradient(192.92deg, #f2f6ff 9.97%, #e1e1f3 82.89%);
    }

    .title {
      color: #ffffff;
    }

    .description {
      color: rgba(255, 255, 255, 0.7);
    }

    .background {
      display: block;
      visibility: visible;
      opacity: 1;
    }

    .purchase {
      display: flex;
      opacity: 1;
      visibility: visible;
    }

    @media (max-width: 400px) {
      .logo {
        background: linear-gradient(192.92deg, #727272 9.97%, #333333 82.89%);
      }

      .title {
        color: #000000;
      }

      .description {
        color: rgba(0, 0, 0, 0.7);
      }

      .background {
        display: none;
      }
    }
  }

  @media (max-width: 400px) {
    .purchase {
      display: flex;
      opacity: 1;
      visibility: visible;
    }
  }
`

const Container = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  height: fit-content;
  gap: 30px;
  text-align: center;
  justify-content: center;
  z-index: 2;
`

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(192.92deg, #727272 9.97%, #333333 82.89%);
  border-radius: 30px;
  top: 0;
  left: 0;
  transform: scale(1.1);

  display: none;
  visibility: hidden;
  opacity: 0;
`

const LogoContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 auto;
  background: linear-gradient(192.92deg, #727272 9.97%, #333333 82.89%);
  mix-blend-mode: normal;
  backdrop-filter: blur(15px);
`

const Logo = styled.img`
  width: 90px;
  margin: 0;
`

const Title = styled(TextStyles.H4)`
  color: #000000;
`

const Description = styled(TextStyles.Caption)`
  color: rgba(0, 0, 0, 0.7);
  max-width: 240px;
  height: 76px;
  margin: 0 auto;
`
