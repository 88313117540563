export const downloadData = [
  {
    type: "sketch",
    logo: "/images/logos/sketch-glass-logo.webp",
    title: "Angle for Sketch",
    description:
      "Download our entire library of mockups for Sketch along with our exclusive Sketch plugin.",
    buttonTitle: "Download Sketch",
    library: "proSketch",
  },
  {
    type: "figma",
    logo: "/images/logos/figma-glass-logo.webp",
    title: "Angle for Figma",
    description:
      "Yes, now you will be able to have all of your Angle mockups in Figma. Just drag and drop the .fig file and you're good to go.",
    buttonTitle: "Download Figma",
    library: "proFigma",
  },
  {
    type: "xd",
    logo: "/images/logos/xd-glass-logo.webp",
    title: "Angle for XD",
    description:
      "Download angle mockups for Adobe XD. You can download our plugin right inside XD’s plugin tab.",
    buttonTitle: "Download XD",
    library: "proXD",
  },
  {
    type: "wallpaper",
    logo: "/images/logos/wallpaper-glass-logo.webp",
    title: "Wallpapers in 5K",
    description: "Download all 190+ Wallpapers for commercial use in your projects.",
    buttonTitle: "Download Wallpapers",
    library: "proWallpaper",
  },
]
